








import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "filter-radio-button",
  props: {
    label: { type: String, default: "" },
    item: { type: [String, Number, Boolean], required: true },
    iconName: { type: String, default: "" },
    value: { type: Boolean, required: true },
    theme: { type: String as PropType<"black" | "white">, default: "white" },
  },
  computed: {
    state: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit("input", value ? this.item : undefined);
      },
    },
    iconComponent() {
      return async () => {
        const component = await import(`@/components/place/filter/icons/${this.iconName}.vue`).catch((error) => {
          console.log(error);
          return;
        });

        return component ?? import(`@/components/place/filter/icons/default.vue`);
      };
    },
  },
});
