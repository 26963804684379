






























import Vue from "vue";

import { Place as BackendPlace, Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import LabelSet from "@/components/place/label-set.vue";
import VacancyText from "@/components/place/vacancy-text.vue";
import VButton from "@/components/v/button.vue";
import Icon from "@/components/v/icon.vue";
import CountText from "../count-text.vue";

type Equipment = BackendPlace.Equipment;
type HotSpringSettingValue = Http.HotSpringSettingValue;

export default Vue.extend({
  name: "panel-summary",
  components: {
    LabelSet,
    VacancyText,
    VButton,
    Icon,
    CountText,
  },
  props: {
    floor: { type: String, required: false, default: "" },
    area: { type: String, required: false, default: "" },
    name: { type: String, required: false, default: "-" },
    categoryList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    hasCoupon: { type: Boolean, default: false },
    // vacancy-text props
    vacancyStatus: { type: String, required: false, default: "closed" },
    vacancyText: { type: String, required: false, default: "-" },
    countText: { type: String, required: false, default: "-" },
    vacancyStyle: { type: String, required: false, default: "level" },
    // label-set props
    openingHoursList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    // feature props
    featureList: { type: Array as () => string[], required: false, default: (): string[] => [] },
    // onsen props
    hotSpringOpeningHoursList: {
      type: Array as () => HotSpringSettingValue[],
      required: false,
      default: (): HotSpringSettingValue[] => [],
    },
    currentGender: { type: String, required: false, default: undefined },
    // display settings
    isDisplayBusinessHour: { type: Boolean, default: true },
    placeIdHash: { type: String, required: true, default: undefined },
  },
  computed: {
    showFloor(): boolean {
      return this.floor.length > 0;
    },
    showCategoryList(): boolean {
      return this.categoryList.length > 0;
    },
    isShopClosed(): boolean {
      return this.vacancyStatus === "closed" || this.vacancyStatus === "lo" || this.vacancyStatus === "maintenance";
    },
    locale(): string {
      return this.$i18n.locale;
    },
  },
});
