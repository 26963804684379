




















































































import Vue, { PropType } from "vue";

import ToggleButton from "@/components/place/filter/toggle-button.vue";
import RadioButton from "@/components/place/filter/radio-button.vue";

import { Category, CategoryOption, Area, FilterOptions } from "@/components/place/filter/filter.interface";

export default Vue.extend({
  name: "place-filter-options",
  components: {
    ToggleButton,
    RadioButton,
  },
  props: {
    categoryList: { type: Array as PropType<CategoryOption[]>, default: () => [] },
    floorList: { type: Array as PropType<string[]>, default: () => [] },
    areaList: { type: Array as PropType<Area[]>, default: () => [] },
    initialStatus: {
      type: Object as PropType<Record<"vacancy" | "qticket" | "opening", boolean>>,
      default: () => ({ vacancy: false, qticket: false, opening: false }),
    },
    initialGenreId: { type: Number, default: undefined },
    initialCategoryIdList: { type: Array as PropType<number[]>, default: () => [] },
    initialFloorList: { type: Array as PropType<string[]>, default: () => [] },
    initialAreaIdList: { type: Array as PropType<number[]>, default: () => [] },
  },
  data() {
    return {
      selectingStatus: {
        vacancy: false,
        qticket: false,
        opening: false,
      },
      selectingGenreId: undefined as number | undefined,
      selectingCategoryIdList: [] as number[],
      selectingFloorList: [] as string[],
      selectingAreaIdList: [] as number[],
    };
  },
  computed: {
    subCategoryList(): Category[] | undefined {
      return this.categoryList.find((category) => category.rootCategory.categoryId === this.selectingGenreId)
        ?.subCategoryList;
    },
    website() {
      return this.$store.getters.getWebsite;
    },
    theme(): "black" | "white" {
      return this.website.theme === "black" ? "black" : "white";
    },
  },
  watch: {
    selectingGenreId: {
      handler() {
        this.selectingCategoryIdList = [];
      },
    },
  },
  mounted() {
    this.selectingStatus = { ...this.initialStatus };
    this.selectingGenreId = this.initialGenreId;
    this.selectingFloorList = [...this.initialFloorList];
    this.selectingAreaIdList = [...this.initialAreaIdList];
    setTimeout(() => {
      // avoid to overwrite in watch handler for selectingGenreId
      this.selectingCategoryIdList = [...this.initialCategoryIdList];
    });
  },
  methods: {
    getCategoryType(category: Category): string {
      switch (category.categoryId) {
        case 1:
          return "restaurant";
        case 40:
          return "office";
        case 41:
          return "toilet";
        case 42:
          return "entertainment";
        case 43:
          return "health-care";
        case 44:
          return "hotel";
        case 45:
          return "community-facility";
        case 46:
          return "service";
        case 47:
          return "shop";
        case 48:
          return "transportation";
        case 49:
          return "art";
        case 50:
          return "landmark";
        case 51:
          return "medical";
        case 52:
          return "beauty-spas";
        case 53:
          return "public-places";
        case 56:
          return "evacuation-center";
        case 57:
          return "tourist-attraction";
        case 107:
          return "polling-place";
        case 108:
          return "finance";
      }
      return "";
    },
    setGenre(genreId: number | undefined) {
      this.selectingGenreId = this.selectingGenreId === genreId ? undefined : genreId;
    },
    setCategory(subCategoryId: number, state: boolean) {
      if (state && !this.selectingCategoryIdList.includes(subCategoryId)) {
        this.selectingCategoryIdList.push(subCategoryId);
      }
      if (!state) {
        this.selectingCategoryIdList = this.selectingCategoryIdList.filter(
          (categoryId) => categoryId !== subCategoryId,
        );
      }
    },
    setFloor(floor: string, state: boolean) {
      if (state && !this.selectingFloorList.includes(floor)) {
        this.selectingFloorList.push(floor);
      }
      if (!state) {
        this.selectingFloorList = this.selectingFloorList.filter((selectingFloor) => selectingFloor !== floor);
      }
    },
    setArea(areaId: number, state: boolean) {
      if (state && !this.selectingAreaIdList.includes(areaId)) {
        this.selectingAreaIdList.push(areaId);
      }
      if (!state) {
        this.selectingAreaIdList = this.selectingAreaIdList.filter((selectingArea) => selectingArea !== areaId);
      }
    },
    clearOptions() {
      this.selectingStatus = {
        vacancy: false,
        qticket: false,
        opening: false,
      };
      this.selectingGenreId = undefined;
      this.selectingCategoryIdList = [];
      this.selectingFloorList = [];
      this.selectingAreaIdList = [];
    },
    applyFilter() {
      const options: FilterOptions = {
        status: { ...this.selectingStatus },
        genreId: this.selectingGenreId,
        categoryIdList: [...this.selectingCategoryIdList],
        floorList: [...this.selectingFloorList],
        areaIdList: [...this.selectingAreaIdList],
      };
      this.$emit("apply", options);
    },
  },
});
