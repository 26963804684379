








import Vue, { PropType } from "vue";
export default Vue.extend({
  name: "filter-toggle-button",
  props: {
    label: { type: String, default: "" },
    iconName: { type: String, default: "" },
    value: { type: Boolean, default: false },
    theme: { type: String as PropType<"black" | "white">, default: "white" },
  },
  computed: {
    state: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit("input", value);
      },
    },
    iconComponent() {
      return async () => {
        const component = await import(`@/components/place/filter/icons/${this.iconName}.vue`).catch((error) => {
          console.log(error);
          return;
        });

        return component ?? import(`@/components/place/filter/icons/default.vue`);
      };
    },
  },
});
