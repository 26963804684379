







import Vue from "vue";

import FallbackImage from "@/components/fallback-image.vue";

interface DataImageWithFallback {
  loadError: boolean;
}

export default Vue.extend({
  name: "image-with-fallback",
  components: {
    FallbackImage,
  },
  props: {
    src: { type: String, default: "" },
  },
  data(): DataImageWithFallback {
    return {
      loadError: false,
    };
  },
});
