import { WebsiteNotFoundError, ContentNotFoundError, isWebsiteNotFoundResponseData } from "./../utils/errors";
import dayjs from "dayjs";
import { $httpEnterpriseVacanAdapter } from "@/api/http";
import { Vacancy, Http, Place } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import { getLocaleForAPI } from "@/plugins/i18n";

import store from "@/store"; // vuex store
const ConnectionError = { message: "connection error", name: "" };

/**
 * display network error pop up with reload button
 */
function displayNetworkAlertErrorView(status = true): void {
    store.commit("setAlertView", {
        status,
    });
}

export function fetchPlaceListByWebsiteId(websiteId: string): Promise<Http.ResponseGetPlacesInWebsite | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseGetPlacesInWebsite>(`/v1/websites/${websiteId}`, {
            params: { namespace, language: getLocaleForAPI() },
        })
        .then((response) => {
            displayNetworkAlertErrorView(false);
            return response.status === 204 ? undefined : response.data;
        })
        .catch((error) => {
            displayNetworkAlertErrorView();
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export async function fetchPlaceDetailByPlaceIdHash(
    websiteId: string,
    placeIdHash: string,
): Promise<Place.PlaceDetail> {
    return await $httpEnterpriseVacanAdapter
        .get<Place.PlaceDetail>(`/v1/websites/${websiteId}/places/${placeIdHash}`, {
            params: { language: getLocaleForAPI(), unixtime: dayjs().unix() },
        })
        .then((response) => {
            displayNetworkAlertErrorView(false);
            return response.data;
        })
        .catch((error) => {
            if (error.response?.status === 404 && isWebsiteNotFoundResponseData(error.response.data)) {
                throw new WebsiteNotFoundError();
            }

            if (400 <= error.response?.status && error.response?.status < 500) {
                throw new ContentNotFoundError();
            }
            displayNetworkAlertErrorView();
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchResponseHotSpringValuesByPlaceIdHashList(
    placeIdHashList: string[],
): Promise<Http.ResponseHotSpringValues[] | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseHotSpringValues[]>(`/v1/places/${placeIdHashList.join(",")}/hotspring/settings`, {
            params: { namespace, language: getLocaleForAPI() },
        })
        .then((response) => (response.status === 204 ? undefined : response.data))
        .catch((error) => {
            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchVacancyListByPlaceIdHashList(placeIdHashList: string[]): Promise<Vacancy.Vacancy[]> {
    const params: Record<string, string> = { language: getLocaleForAPI() };

    return $httpEnterpriseVacanAdapter
        .get<Vacancy.Vacancy[]>(`/v1/places/${placeIdHashList.join(",")}/vacancies`, { params })
        .then((response) => response.data)
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchPlaceFilterOptions(websiteId: string): Promise<Http.ResponseGetPlaceFilterOptions | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseGetPlaceFilterOptions>(`/v1/filter-options/websites/${websiteId}`, {
            params: { namespace, language: getLocaleForAPI() },
        })
        .then((response) => (response.status === 204 ? undefined : response.data))
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}

export function fetchFilteredPlaceListByWebsiteId(
    websiteId: string,
    offset?: number,
    limit?: number,
    categoryIdList?: number[],
    floorList?: string[],
    areaIdList?: number[],
    featureList?: string[],
    vacancyList?: string[],
): Promise<Http.ResponseGetPlacesInWebsite | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseGetPlacesInWebsite>(`/v1/websites/${websiteId}`, {
            params: {
                namespace,
                language: getLocaleForAPI(),
                offset,
                limit,
                categoryIdList: categoryIdList?.join(",") || undefined,
                floorList: floorList?.join(",") || undefined,
                areaIdList: areaIdList?.join(",") || undefined,
                featureList: featureList?.join(",") || undefined,
                vacancyList: vacancyList?.join(",") || undefined,
            },
        })
        .then((response) => {
            displayNetworkAlertErrorView(false);
            return response.status === 204 ? undefined : response.data;
        })
        .catch((error) => {
            displayNetworkAlertErrorView();
            if (error.response?.status === 500) {
                return undefined;
            } else if (400 <= error.response?.status && error.response?.status < 500) {
                throw new WebsiteNotFoundError();
            }

            return Promise.reject(error.response ? error.response.data.errors : ConnectionError);
        });
}

export function fetchUpdatedFilters(
    placeIdHashList: string[],
): Promise<Http.ResponseGetPlaceFilterOptions | undefined> {
    const namespace = "enterprise";
    return $httpEnterpriseVacanAdapter
        .get<Http.ResponseGetPlaceFilterOptions>(`/v1/filter-options/place/${placeIdHashList.join(",")}`, {
            params: { namespace, language: getLocaleForAPI() },
        })
        .then((response) => (response.status === 204 ? undefined : response.data))
        .catch((error) => Promise.reject(error.response ? error.response.data.errors : ConnectionError));
}
