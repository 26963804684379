import Vue from "vue";
import App from "./app.vue";
// TODO: I wish to un-comment registering service workers
// import "./register-service-worker";
import router from "./router";
import store from "./store";
import "@/plugins/gtags";
import { i18n } from "@/plugins/i18n";
import { WebsiteNotFoundError, ContentNotFoundError, NotFoundError } from "./utils/errors";

Vue.config.productionTip = false;

Vue.config.errorHandler = (error) => {
    if (error instanceof ContentNotFoundError) {
        router.replace({ name: "WebsiteError" });
        return;
    }

    if (error instanceof WebsiteNotFoundError) {
        router.replace({ name: "Error" });
        return;
    }

    if (error instanceof NotFoundError) {
        router.replace({ name: "Error" });
        return;
    }
};

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
