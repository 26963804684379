import Vue from "vue";
import VueI18n, { LocaleMessages } from "vue-i18n";
import { Locales } from "locale";

import "dayjs/locale/ja";
import "dayjs/locale/zh";
import "dayjs/locale/ko";
import "dayjs/locale/zh-tw";
import "dayjs/locale/zh-cn";
import dayjs from "dayjs";

Vue.use(VueI18n);

function loadLocaleMessages(): LocaleMessages {
    const locales = require.context("../locales", true, /[A-Za-z0-9-_,\s]+\.json$/i);

    const messages: LocaleMessages = {};
    locales.keys().forEach((key: string) => {
        const matched = key.match(/([A-Za-z0-9-_,\s]+)\./i);
        if (matched && matched.length > 1) {
            // match file names
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}

const browserLocale: string =
    (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language || "en";

const supportLanguage = ["en", "ja", "ko-KR", "zh-CN", "zh-TW"] as const; // correspond to file name of json files in locales directory
type SupportLanguage = typeof supportLanguage[number];

const supportLanguageForAPI = ["en", "ja", "ko_KR", "zh_CN", "zh_TW"] as const;
type SupportLanguageForAPI = typeof supportLanguageForAPI[number];

const interpreterAsSupportLanguage = (queryLanguage: string): SupportLanguage => {
    const supported: Locales = new Locales(supportLanguage);
    const locales: Locales = new Locales(queryLanguage);
    return locales.best(supported).toString() as SupportLanguage;
};

// zh の時には zh-TW にするという仕様。この仕様でいいのか知らんけど。
const interpretedLocale = (locale: string): SupportLanguage =>
    locale === "zh" ? interpreterAsSupportLanguage("zh-TW") : interpreterAsSupportLanguage(locale);

export const i18n = new VueI18n({
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
    messages: loadLocaleMessages(), // load from file
});

export const setLocale = (locale: string): void => {
    const safeLocale = interpretedLocale(locale);
    i18n.locale = safeLocale;
    dayjs.locale(safeLocale);
};

export const getLocaleForAPI = (): SupportLanguageForAPI => {
    return i18n.locale.replace("-", "_") as SupportLanguageForAPI;
};

// QTが202208現在、韓国語対応してないので、QT系のエンドポイント英語にフォールバックするメソッドを用意。
export const getLocaleForQT = (): SupportLanguageForAPI => {
    const language: string = i18n.locale !== "ko-KR" ? i18n.locale : "en";
    return language.replace("-", "_") as SupportLanguageForAPI;
};

setLocale(browserLocale);
