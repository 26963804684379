




















import Vue from "vue";

import TheFooter from "@/components/the-footer.vue";
import { mapGetters } from "vuex";
import { TERMS_OF_PAGES_URL } from "./utils/terms-of-service";

export default Vue.extend({
  name: "app",
  components: {
    TheFooter,
  },
  data() {
    return {
      currentTheme: "white",
      isBlackTheme: false,
    };
  },
  computed: {
    ...mapGetters({
      website: "getWebsite",
    }),
    termsUrl(): string | undefined {
      const nolineTermsRouteList = [/QT\/*/];

      const useNolineTerms = nolineTermsRouteList.reduce((result, route) => {
        return result || route.test(this.$route.name || "");
      }, false);

      return useNolineTerms ? "/terms-of-service.html" : TERMS_OF_PAGES_URL;
    },
    enableDevTools(): boolean {
      return process.env.NODE_ENV === "development";
    },
    /**
     * Remove this if unnecessary
     */
    showFooter(): boolean {
      const hiddenFooterRouteNames = new Set(["Maintenance", "ImageGallery"]);
      const currentRouteName = this.$route.name;
      return !!currentRouteName && !hiddenFooterRouteNames.has(currentRouteName);
    },
    isPlaceList(): boolean {
      return this.$route.name === "Index" || this.$route.name === "Place";
    },
  },
  watch: {
    $route: function (newRoute) {
      const blackThemeRouteSet = new Set(["Index", "Place"]);
      if (this.$store.state.theme === "black") {
        if (newRoute.name === "Place") {
          this.currentTheme = "place-black";
        } else {
          this.currentTheme = "dark-black";
        }
        document.documentElement.setAttribute("data-theme", "black");
        if (!blackThemeRouteSet.has(newRoute.name)) {
          this.currentTheme = "white";
          document.documentElement.setAttribute("data-theme", "");
        }
        if (newRoute.name === "Coupons") {
          this.currentTheme = "black";
        }
      }
      // check for specific place route
    },
    website: {
      handler: function (website) {
        if (website.theme === "black") {
          document.documentElement.setAttribute("data-theme", "black");
          this.isBlackTheme = true;
          if (this.$route.name === "Place") {
            this.currentTheme = "place-black";
          } else {
            this.currentTheme = "dark-black";
          }
        } else {
          document.documentElement.setAttribute("data-theme", "");
          this.currentTheme = "white";
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.isBlackTheme = this.website.theme !== "white";

    // FIXME: remove this after the maintenance is over with the next release
    const startMaintenanceTime = 1621270800;
    const endMaintenanceTime = 1621274400;
    const currentUnixtime = new Date().getTime() / 1000;

    // Check if maintenance time has passed
    if (currentUnixtime <= endMaintenanceTime) {
      // Check if you visit the page between the time
      if (currentUnixtime >= startMaintenanceTime && this.$route.name !== "Maintenance") {
        this.$router.push("/maintenance");
      }
      // Set all the current loaded stuff to maintenance
      setTimeout(() => {
        this.$router.push("/maintenance").catch(() => {
          console.info("already navigated to maintenance");
        });
        // Return to the previous page after 1 hour
      }, (startMaintenanceTime - currentUnixtime) * 1000);
      setTimeout(() => {
        this.$router.go(-1);
      }, (endMaintenanceTime - currentUnixtime) * 1000);
    }
  },
  methods: {
    toggleTheme() {
      this.$store.commit("setPageTheme", this.currentTheme === "white" ? "black" : "white");
    },
    theme(): "white" | "black" | "dark-black" | "place-black" {
      const blackThemeRouteList = ["Coupons"];

      if (blackThemeRouteList.includes(this.$route.name || "")) {
        return "black";
      }
      if (this.website.theme === "black") {
        document.documentElement.setAttribute("data-theme", "black");
        if (this.$route.name === "Place") {
          return "place-black";
        }
        return "dark-black";
      }
      return "white";
    },
  },
});
