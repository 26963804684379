


















import Vue from "vue";

import PlaceSingle from "@/components/place/single/index.vue";
import PlaceMultiple from "@/components/place/multiple/index.vue";
import FullscreenAlertReload from "@/components/fullscreen-alert-reload.vue";
import CommonAnnouncementModal from "@/components/common-announcement-modal.vue";

import { mapGetters, mapState } from "vuex";
import ClosedMediaView from "@/components/closed-media.vue";

type DisplayMode = "single" | "multiple" | "none";

interface DataViewsIndex {
  initialPlaceList: Record<string, unknown>[];
  timer: {
    tryingSetup: number | undefined;
  };
}

export default Vue.extend({
  name: "views-index",
  components: {
    PlaceSingle,
    PlaceMultiple,
    FullscreenAlertReload,
    CommonAnnouncementModal,
    ClosedMediaView,
  },
  data(): DataViewsIndex {
    return {
      initialPlaceList: [],
      timer: {
        tryingSetup: undefined,
      },
    };
  },
  computed: {
    ...mapGetters({
      website: "getWebsite",
      placeList: "getPlaceList",
    }),
    ...mapState({
      $alertView: "alertView",
    }),
    hasAtLeastOnePlace(): boolean {
      return this.initialPlaceList.length > 0;
    },
    displayMode(): DisplayMode {
      if (!this.hasAtLeastOnePlace) {
        return "none";
      }
      if (this.initialPlaceList.length === 1 && this.website.filtering) {
        return "multiple";
      }
      return this.initialPlaceList.length === 1 ? "single" : "multiple";
    },
  },
  async created() {
    await this.setup();
    const manifestTag = document.querySelector("link[rel=manifest]");
    manifestTag?.setAttribute("href", `/api/v1/websites/${this.$route.params.websiteId}/manifests`);
  },
  beforeDestroy() {
    this.teardown();
  },
  methods: {
    async setup(): Promise<void> {
      const websiteId = this.$route.params.websiteId;
      if (this.website.id !== websiteId) {
        await this.$store.dispatch("fetchWebsite", this.$route.params.websiteId);
      }
      this.initialPlaceList = [...this.placeList];

      if (!this.placeList.length) {
        this.timer.tryingSetup = window.setTimeout(() => {
          this.setup();
        }, 30 * 1000);
      }
    },
    teardown(): void {
      if (this.timer.tryingSetup !== undefined) {
        clearTimeout(this.timer.tryingSetup);
      }
    },
  },
});
