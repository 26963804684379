






























































import Vue from "vue";
import FullscreenAlertReload from "@/components/fullscreen-alert-reload.vue";
import CommonAnnouncementModal from "@/components/common-announcement-modal.vue";

import { mapState } from "vuex";

import {
  Vacancy as BackendVacancy,
  Place as BackendPlace,
  Http,
  Ticket,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import {
  fetchVacancyListByPlaceIdHashList,
  fetchResponseHotSpringValuesByPlaceIdHashList,
} from "@/api/enterprise-vacan.adapter.api";

import PlacePanelImage from "@/components/place/panel/image.vue";
import PlacePanelSummary from "@/components/place/panel/summary.vue";
import PlacePanelBasic from "@/components/place/panel/basic.vue";
import PlacePanelMedia from "@/components/place/panel/media.vue";
import { ContentNotFoundError, WebsiteNotFoundError } from "@/utils/errors";
import router from "../router";
import ClosedMediaView from "@/components/closed-media.vue";

type PlaceDetail = BackendPlace.PlaceDetail;
type Vacancy = BackendVacancy.Vacancy;
type HotSpringSetting = Http.ResponseHotSpringValues;

interface DataViewsPlaces {
  placeDetail: PlaceDetail | undefined;
  vacancy: Vacancy | undefined;
  hotSpringSetting: HotSpringSetting | undefined;
  qticketConfig?: Ticket.TicketConfig;
  timer: {
    fetchingVacancies: number | undefined;
    tryingSetup: number | undefined;
  };
  pageStatus: "ok" | "preparing" | "error" | "closed";
  lastFetchedVacancyUnixTime: number;
}

export default Vue.extend({
  name: "views-places",
  components: {
    PlacePanelImage,
    PlacePanelSummary,
    PlacePanelBasic,
    PlacePanelMedia,
    FullscreenAlertReload,
    CommonAnnouncementModal,
    ClosedMediaView,
  },
  props: {
    websiteId: { type: String, required: true },
    placeIdHash: { type: String, required: true },
  },
  data(): DataViewsPlaces {
    return {
      placeDetail: undefined,
      vacancy: undefined,
      hotSpringSetting: undefined,
      qticketConfig: undefined,
      timer: { fetchingVacancies: undefined, tryingSetup: undefined },
      pageStatus: "preparing",
      lastFetchedVacancyUnixTime: new Date().getTime() / 1000,
    };
  },
  computed: {
    ...mapState({
      $alertView: "alertView",
    }),
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpringSetting === undefined ? undefined : this.hotSpringSetting.currentGender;
    },
    showBusinessHour(): boolean {
      return this.placeDetail?.displaySetting?.isDisplayBusinessHour ?? true;
    },
    showRegularHoliday(): boolean {
      return this.placeDetail?.displaySetting?.isDisplayRegularHoliday ?? true;
    },
    showContactMessage(): boolean {
      return this.placeDetail?.displaySetting?.isDisplayContactMessage ?? true;
    },
  },
  async mounted() {
    const manifestTag = document.querySelector("link[rel=manifest]");
    manifestTag?.setAttribute("href", `/api/v1/websites/${this.$route.params.websiteId}/manifests`);
    this.setup();
  },
  beforeDestroy() {
    this.teardown();
  },
  methods: {
    async setup(): Promise<void> {
      this.pageStatus = "preparing";

      this.placeDetail = this.$store.getters.getPlaceDetail(this.placeIdHash);

      const promise = this.placeDetail
        ? Promise.resolve()
        : this.$store
            .dispatch("fetchPlaceDetail", { websiteId: this.websiteId, placeIdHash: this.placeIdHash })
            .then(() => {
              this.placeDetail = this.$store.getters.getPlaceDetail(this.placeIdHash);
            });

      await promise
        .then(async () => {
          if (this.placeDetail?.isAvailable === false) {
            this.pageStatus = "closed";
            return;
          }

          this.pageStatus = "ok";
          await this.fetchHotSpringSetting();
          await this.fetchVacancy();
          this.loop();
        })
        .catch((error) => {
          if (error instanceof ContentNotFoundError) {
            router.replace({ name: "WebsiteError" });
            return;
          } else if (error instanceof WebsiteNotFoundError) {
            router.replace({ name: "Error" });
            return;
          }

          this.pageStatus = "error";

          this.timer.tryingSetup = window.setTimeout(() => {
            this.setup();
          }, 30 * 1000);
        });
    },
    loop(): void {
      this.timer.fetchingVacancies = window.setTimeout(async () => {
        await this.fetchVacancy().catch(() => {
          this.pageStatus = "error";
          this.teardown();
        });
        await this.fetchHotSpringSetting().catch(() => {
          this.pageStatus = "error";
          this.teardown();
        });
        this.loop();
      }, 5000);
    },
    teardown(): void {
      if (this.timer.fetchingVacancies !== undefined) {
        clearTimeout(this.timer.fetchingVacancies);
      }

      if (this.timer.tryingSetup !== undefined) {
        clearTimeout(this.timer.tryingSetup);
      }
    },
    async fetchVacancy(): Promise<void> {
      try {
        const vacancyList: Vacancy[] = await fetchVacancyListByPlaceIdHashList([this.placeIdHash]);
        this.lastFetchedVacancyUnixTime = new Date().getTime();
        this.$store.commit("setAlertView", {
          status: false,
        });

        const vacancy: Vacancy | undefined = vacancyList.shift();
        if (vacancy !== undefined) {
          this.vacancy = vacancy;
        }
      } catch {
        const NETWORK_ERROR_THRESHOLD = 3 * 60 * 1000;
        const currentUnixTime = new Date().getTime();
        if (currentUnixTime - NETWORK_ERROR_THRESHOLD >= this.lastFetchedVacancyUnixTime) {
          this.$store.commit("setAlertView", {
            status: true,
          });
        }
      }
    },
    async fetchHotSpringSetting(): Promise<void> {
      const hotSpringSettingList = await fetchResponseHotSpringValuesByPlaceIdHashList([this.placeIdHash]);
      const hotSpringSetting: HotSpringSetting | undefined = hotSpringSettingList?.shift();
      if (hotSpringSetting !== undefined) {
        this.hotSpringSetting = hotSpringSetting;
      }
    },
  },
});
