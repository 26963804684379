



























import Vue from "vue";

export default Vue.extend({
  name: "views-error",
  props: {
    websiteId: { type: String, default: undefined },
  },
});
