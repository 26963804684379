




// https://material.io/icons/
import Vue from "vue";

export default Vue.extend({
  name: "v-icon",
  props: {
    name: { type: String, default: "", required: false },
  },
});
