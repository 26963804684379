import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import Error from "@/views/error.vue";
import Index from "@/views/index.vue";
import Places from "@/views/places.vue";
import ImageGallery from "@/views/image-gallery.vue";
import Coupons from "@/views/coupons.vue";
import Maintenance from "@/views/maintenance.vue";
import { setLocale } from "@/plugins/i18n";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    { path: "/maintenance", name: "Maintenance", component: Maintenance },
    { path: "/error", name: "Error", component: Error },
    { path: "/:websiteId/error", name: "WebsiteError", component: Error, props: true },
    { path: "/:websiteId", name: "Index", component: Index, props: true },
    { path: "/:websiteId/places/:placeIdHash", name: "Place", component: Places, props: true },
    { path: "/:websiteId/places/:placeIdHash/images", name: "ImageGallery", component: ImageGallery, props: true },
    { path: "/:websiteId/places/:placeIdHash/coupons", name: "Coupons", component: Coupons, props: true },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, _, next) => {
    if (typeof to.query.lang === "string") {
        setLocale(to.query.lang);
    }

    next();
});

export default router;
