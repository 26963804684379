






import Vue from "vue";
export default Vue.extend({
  name: "v-button",
});
