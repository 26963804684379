



































import Vue, { PropType } from "vue";
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";

export default Vue.extend({
  name: "place-panel-image",
  components: {
    Hooper,
    Slide,
    HooperPagination,
  },
  props: {
    srcList: { type: Array as PropType<string[]>, required: false, default: (): string[] => [] },
    websiteId: { type: String, required: true },
    placeIdHash: { type: String, required: true },
  },
  data() {
    return {
      hooperSettings: {
        pagination: true,
        infiniteScroll: true,
        playSpeed: 4000,
        wheelControl: false,
        keysControl: false,
      },
      swipeStartPositionX: undefined as number | undefined,
    };
  },
  methods: {
    touchSlideStart(event: TouchEvent) {
      const touch = event.touches[0];
      if (!touch) {
        return;
      }
      this.swipeStartPositionX = touch.clientX;

      const body = document.querySelector("body");
      if (!body) {
        return;
      }
      body.style.overflow = "hidden";
      body.style.overscrollBehavior = "none";
    },
    touchSlideEnd(event: TouchEvent, index: number) {
      const touch = event.changedTouches[0];
      if (!touch || this.swipeStartPositionX === undefined) {
        this.swipeStartPositionX = undefined;
        return;
      }

      if (Math.abs(touch.clientX - this.swipeStartPositionX) < 10) {
        this.$router.push({
          name: "ImageGallery",
          params: { websiteId: this.websiteId, placeIdHash: this.placeIdHash },
          query: { slides: index.toString() },
        });
      }
      this.swipeStartPositionX = undefined;

      const body = document.querySelector("body");
      if (!body) {
        return;
      }
      body.style.overflow = "auto";
      body.style.overscrollBehavior = "auto";
    },
    mouseDownOnSlide(event: MouseEvent) {
      this.swipeStartPositionX = event.clientX;
    },
    mouseUpOnSlide(event: MouseEvent, index: number) {
      if (this.swipeStartPositionX === undefined) {
        return;
      }

      if (Math.abs(event.clientX - this.swipeStartPositionX) < 10) {
        this.$router.push({
          name: "ImageGallery",
          params: { websiteId: this.websiteId, placeIdHash: this.placeIdHash },
          query: { slides: index.toString() },
        });
      }
      this.swipeStartPositionX = undefined;
    },
  },
});
