

















import Vue from "vue";

import VButton from "@/components/v/button.vue";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import * as storage from "@/api/local-storage";

dayjs.extend(isSameOrBefore);

interface CommonAnnouncementModal {
  isShowModal: boolean;
}

export default Vue.extend({
  name: "common-announcement-modal",
  components: {
    VButton,
  },
  data(): CommonAnnouncementModal {
    return {
      isShowModal: true,
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";

    const now = dayjs(new Date().toLocaleString("ja-JP", { timeZone: "Asia/Tokyo" }));
    if (!now.isSameOrBefore("2022-07-21 02:00:00") || storage.get("maintenance202207")) {
      this.closeModal();
      return;
    }
    storage.set("maintenance202207", true);
    this.setCloseTimer();
  },
  beforeDestroy() {
    document.body.style.overflow = "auto";
  },
  methods: {
    closeModal(): void {
      this.isShowModal = false;
      document.body.style.overflow = "auto";
    },
    setCloseTimer() {
      setTimeout(() => {
        this.closeModal();
      }, 60 * 1000);
    },
  },
});
