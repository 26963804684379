/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export function set(key: string, value: any): void {
    localStorage.setItem(`qt/${key}`, JSON.stringify(value));
}

export function remove(key: string): void {
    localStorage.removeItem(`qt/${key}`);
}

export function get(key: string): Record<string, unknown> {
    const item = localStorage.getItem(`qt/${key}`);
    return item !== null ? JSON.parse(item) : undefined;
}
