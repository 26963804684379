import {
    fetchFilteredPlaceListByWebsiteId,
    fetchPlaceDetailByPlaceIdHash,
    fetchPlaceFilterOptions,
    fetchUpdatedFilters,
} from "@/api/enterprise-vacan.adapter.api";
import { Place } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import Vue from "vue";
import Vuex, { ActionTree, GetterTree, MutationTree } from "vuex";

Vue.use(Vuex);
interface Website {
    id: string;
    name: string;
    questionnaireUrl: string;
    theme: string;
    filtering: boolean;
}

export type Weekday = {
    date: string;
    unixtime: number;
    isWorkday: boolean;
};

type Category = {
    categoryId: number;
    categoryName: string;
};
type CategoryOption = {
    rootCategory: Category;
    subCategoryList: Category[];
};
type Area = {
    areaId: number;
    areaName: string;
};

type AlertView = {
    status: boolean;
    msg: string;
    btnTitle: string;
};

interface AppState {
    website: Website;
    placeList: Place.Place[];
    placeDetail: Record<string, Place.PlaceDetail>;
    categoryList: CategoryOption[];
    floorList: string[];
    areaList: Area[];
    defaultCategoryList: CategoryOption[];
    defaultFloorList: string[];
    defaultAreaList: Area[];
    requestedOffset: Set<number>;
    alertView: AlertView;
}

const state: AppState = {
    website: {
        id: "",
        name: "",
        questionnaireUrl: "",
        theme: "white",
        filtering: false,
    },
    placeList: [],
    placeDetail: {},
    categoryList: [],
    floorList: [],
    areaList: [],
    defaultCategoryList: [],
    defaultFloorList: [],
    defaultAreaList: [],
    requestedOffset: new Set<number>(),
    alertView: {
        status: false,
        msg: "",
        btnTitle: "",
    },
};

const mutations: MutationTree<AppState> = {
    setWebsite(state: AppState, website: Website) {
        state.website = website;
    },
    setPlaceList(state: AppState, placeList: Place.Place[]) {
        state.placeList = placeList;
    },
    setPlaceDetail(state: AppState, placeDetail: Place.PlaceDetail) {
        state.placeDetail[placeDetail.placeIdHash] = placeDetail;
    },
    setAdditionalPlaces(state: AppState, placeList: Place.Place[]) {
        if (placeList.length) {
            state.placeList = [...state.placeList, ...placeList];
        }
    },

    setCategoryList(state: AppState, categoryList: CategoryOption[]) {
        state.categoryList = categoryList;
    },
    setFloorList(state: AppState, floorList: string[]) {
        state.floorList = floorList;
    },
    setAreaList(state: AppState, areaList: Area[]) {
        state.areaList = areaList;
    },

    setDefaultFloorList(state: AppState, floorList: string[]) {
        state.defaultFloorList = floorList;
    },
    setDefaultCategoryList(state: AppState, categoryList: CategoryOption[]) {
        state.defaultCategoryList = categoryList;
    },
    setDefaultAreaList(state: AppState, areaList: Area[]) {
        state.defaultAreaList = areaList;
    },

    setPageTheme(state: AppState, theme: string) {
        state.website.theme = theme;
    },
    setAlertView: (
        state: AppState,
        payload: {
            status: boolean;
            msg: string;
            btnTitle: string;
        },
    ) => {
        state.alertView = payload;
    },
};

const getters: GetterTree<AppState, AppState> = {
    getWebsite(state: AppState) {
        return state.website;
    },
    getPlaceList(state: AppState) {
        return state.placeList;
    },
    getPlaceDetail(state: AppState) {
        return (placeIdHash: string): Place.PlaceDetail | undefined => state.placeDetail[placeIdHash];
    },
    getCategoryList(state: AppState) {
        return state.categoryList;
    },
    getFloors(state: AppState) {
        return state.floorList;
    },
    getAreaList(state: AppState) {
        return state.areaList;
    },
};

const actions: ActionTree<AppState, AppState> = {
    async fetchWebsite({ commit }, websiteId: string) {
        const placeList = await fetchFilteredPlaceListByWebsiteId(websiteId);
        if (!placeList) {
            return;
        }
        commit("setWebsite", {
            id: websiteId,
            name: placeList.websiteTitle.name,
            questionnaireUrl: placeList.questionnaireUrl || "",
            theme: placeList.theme || "white",
            filtering: placeList.filtering,
            isAvailable: placeList.isAvailable ?? true,
        });
        commit("setPlaceList", placeList.placeList);

        if (placeList.filtering) {
            const filterOptions = await fetchPlaceFilterOptions(websiteId);
            if (!filterOptions) {
                return;
            }
            commit("setFloorList", filterOptions.floorList);
            commit("setDefaultFloorList", filterOptions.floorList);
            commit("setCategoryList", filterOptions.categoryList);
            commit("setDefaultCategoryList", filterOptions.categoryList);
            commit("setAreaList", filterOptions.areaList);
            commit("setDefaultAreaList", filterOptions.areaList);
        }
    },
    async fetchPlaceDetail({ commit, state }, params: { websiteId: string; placeIdHash: string }) {
        const placeDetail = await fetchPlaceDetailByPlaceIdHash(params.websiteId, params.placeIdHash);
        commit("setPlaceDetail", placeDetail);
        commit("setPageTheme", placeDetail.theme);

        if (state.placeList.length <= 0) {
            commit("setPlaceList", [placeDetail]);
        }
    },

    // Used after the user selects some filters
    async fetchFilteredPlaces(
        { commit },
        {
            websiteId,
            offset,
            limit = 10,
            categoryIdList,
            floorList,
            areaIdList,
            status,
        }: {
            websiteId: string;
            offset: number;
            limit: number;
            categoryIdList?: number[];
            floorList?: string[];
            areaIdList?: number[];
            status: {
                vacancy: boolean;
                qticket: boolean;
                opening: boolean;
            };
        },
    ) {
        const requestVacancyList = [];
        if (status.vacancy) {
            requestVacancyList.push("vacancy");
            requestVacancyList.push("unknown");
        }
        if (status.opening) {
            requestVacancyList.push("opening");
        }
        const placeList = await fetchFilteredPlaceListByWebsiteId(
            websiteId,
            offset,
            limit,
            categoryIdList,
            floorList,
            areaIdList,
            status.qticket ? ["qticket"] : undefined,
            requestVacancyList,
        );
        if (placeList !== undefined) {
            commit("setPlaceList", placeList.placeList);
        }
        // reset requestOffset
        state.requestedOffset = new Set<number>();
    },
    // Used on scroll;
    async fetchMorePlaces(
        { commit },
        {
            websiteId,
            offset,
            limit = 10,
            categoryIdList,
            floorList,
            areaIdList,
            status,
        }: {
            websiteId: string;
            offset: number;
            limit: number;
            categoryIdList?: number[];
            floorList?: string[];
            areaIdList?: number[];
            status: {
                vacancy: boolean;
                qticket: boolean;
                opening: boolean;
            };
        },
    ) {
        // Don't request the same things more than once
        if (!state.requestedOffset.has(offset)) {
            state.requestedOffset.add(offset);
            const requestVacancyList = [];
            if (status.vacancy) {
                requestVacancyList.push("vacancy");
                requestVacancyList.push("unknown");
            }
            if (status.opening) {
                requestVacancyList.push("opening");
            }
            const placeList = await fetchFilteredPlaceListByWebsiteId(
                websiteId,
                offset,
                limit,
                categoryIdList,
                floorList,
                areaIdList,
                status.qticket ? ["qticket"] : undefined,
                requestVacancyList,
            );
            if (placeList !== undefined && placeList.placeList.length > 0) {
                commit("setAdditionalPlaces", placeList.placeList);
            }
        }
    },
    async updateFilters(
        { commit },
        {
            isCategorySelected,
            isFloorSelected,
            isAreaSelected,
        }: {
            isCategorySelected?: boolean;
            isFloorSelected?: boolean;
            isAreaSelected?: boolean;
        },
    ) {
        const placeIdHashList = this.state.placeList.map((place) => place.placeIdHash);
        const filterOptions = await fetchUpdatedFilters(placeIdHashList);
        if (!filterOptions) {
            return;
        }

        commit(
            "setCategoryList",
            isCategorySelected && !isFloorSelected && !isAreaSelected
                ? state.defaultCategoryList
                : filterOptions.categoryList,
        );
        commit(
            "setFloorList",
            isFloorSelected && !isCategorySelected && !isAreaSelected
                ? state.defaultFloorList
                : filterOptions.floorList,
        );
        commit(
            "setAreaList",
            isAreaSelected && !isCategorySelected && !isFloorSelected ? state.defaultAreaList : filterOptions.areaList,
        );

        // refresh requestOffset so you can fetch the correct ones again
        state.requestedOffset = new Set<number>();
    },

    async setDefaultFilterOptions({ commit }) {
        commit("setFloorList", state.defaultFloorList);
        commit("setCategoryList", state.defaultCategoryList);
        commit("setAreaList", state.defaultAreaList);
    },
};

export default new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules: {},
});
