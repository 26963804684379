







import Vue from "vue";

export default Vue.extend({
  name: "the-header",
  props: {
    label: { type: String, default: "" },
  },
});
