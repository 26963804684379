
import Vue from "vue";

import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type VacancyStatus = BackendVacancy.VacancyState;

export default Vue.extend({
  name: "place-mixin",
  props: {
    place: {
      type: Object as () => Place,
      required: false,
      default: (): Place => ({
        placeIdHash: "",
        name: "",
        floor: "",
        area: undefined,
        imageUrlList: [],
        openingHoursList: [],
        equipmentList: [],
        featureList: [],
        couponList: [],
        displaySetting: {
          isDisplayBusinessHour: true,
          isDisplayRegularHoliday: true,
          isDisplayContactMessage: true,
          vacancyStyle: "level",
          enableCongestionForecast: false,
        },
        timeZone: "Asia/Tokyo",
      }),
    },
    vacancy: {
      type: Object as () => Vacancy,
      required: false,
      default: (): Vacancy => ({
        placeIdHash: "",
        status: {
          state: "closed" as VacancyStatus,
          text: "-",
          countText: "-",
          appearance: {
            textColor: "#969696",
            backgroundColor: "#ffffff",
          },
        },
        qticketStatus: {
          isAcceptable: false,
          useSizeLimit: false,
        },
      }),
    },
  },
});
