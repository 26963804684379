export function iPadOS(): boolean {
    const ua = window.navigator.userAgent.toLowerCase();
    return (
        ua.indexOf("ipad") > -1 ||
        ((ua.indexOf("macintosh") > -1 || ua.indexOf("macintel") > -1) && "ontouchend" in document)
    );
}

export function isPC(): boolean {
    const ua = window.navigator.userAgent.toLowerCase();
    return (
        (/macintosh/.test(ua) && !iPadOS()) ||
        (/windows/.test(ua) && !("ontouchend" in document)) ||
        (/linux_x86_64/.test(ua) && !("ontouchend" in document)) ||
        (/linux_i686/.test(ua) && !("ontouchend" in document)) ||
        (/linux i686/.test(ua) && !("ontouchend" in document)) ||
        (/linux x86_64/.test(ua) && !("ontouchend" in document))
    );
}
