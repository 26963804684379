






import Vue from "vue";

export default Vue.extend({
  name: "place-count-text",
  props: {
    status: {
      type: String,
      required: false,
      default: "closed",
      validator: (value) =>
        ["lo", "closed", "open", "vacancy", "crowded", "busy", "full", "waiting", "custom1"].includes(value),
    },
    text: { type: String, required: false, default: "-" },
  },
  computed: {
    isLongText(): boolean {
      return this.text.length > 20;
    },
  },
});
