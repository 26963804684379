
























































import { TERMS_OF_PAGES_URL } from "@/utils/terms-of-service";
import Vue from "vue";

export default Vue.extend({
  name: "the-footer",
  props: {
    theme: {
      type: String,
      required: true,
      default: "white",
      validator: (value) => ["white", "black", "dark-black", "place-black"].includes(value),
    },
    showTerms: { type: Boolean, default: false },
    termsUrl: { type: String, default: TERMS_OF_PAGES_URL },
  },
});
