





















































import Vue from "vue";
import { mapGetters } from "vuex";

import TheHeader from "@/components/the-header.vue";
import PlaceContainer from "@/components/place/container.vue";
import Questionnaire from "@/components/questionnaire.vue";
import FilterPanel from "@/components/place/filter/panel.vue";

import {
  Place as BackendPlace,
  Vacancy as BackendVacancy,
  Http,
  Ticket,
} from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";
import { Category, CategoryOption, Area, FilterOptions } from "@/components/place/filter/filter.interface";

import { fetchVacancyListByPlaceIdHashList } from "@/api/enterprise-vacan.adapter.api";
import { fetchResponseHotSpringValuesByPlaceIdHashList } from "@/api/enterprise-vacan.adapter.api";

type Place = BackendPlace.Place;
type Vacancy = BackendVacancy.Vacancy;
type HotStringSetting = Http.ResponseHotSpringValues;

interface DataPlaceMultipleIndex {
  vacancyList: Vacancy[];
  timer: { fetchingVacancies: number | undefined };
  hotSpringSettingList: HotStringSetting[];
  qticketConfigList: (Ticket.TicketConfig & { placeIdHash: string })[];
  selectedCategoryId: number | undefined;
  currentGenre: Category | undefined;
  rootGenre: Category | undefined;
  currentFloor: string | undefined;
  currentArea: Area | undefined;
  subCategoryList: Category[];
  lastFetchedVacancyUnixTime: number;
  showFilterPanel: boolean;
  filterOptions: FilterOptions;
}

export default Vue.extend({
  name: "multi-place-index",
  components: {
    TheHeader,
    PlaceContainer,
    Questionnaire,
    FilterPanel,
  },
  props: {
    placeList: { type: Array as () => Place[], default: () => [] },
    website: {
      type: Object as () => { id: string; name: string; questionnaireUrl?: string },
      default: (): { id: string; name: string; questionnaireUrl?: string; theme: string; filtering: boolean } => ({
        id: "",
        name: "",
        questionnaireUrl: undefined,
        theme: "white",
        filtering: false,
      }),
    },
  },
  data(): DataPlaceMultipleIndex {
    return {
      vacancyList: [],
      timer: { fetchingVacancies: undefined },
      hotSpringSettingList: [],
      qticketConfigList: [],
      selectedCategoryId: undefined,
      currentGenre: undefined,
      rootGenre: undefined,
      currentFloor: undefined,
      currentArea: undefined,
      subCategoryList: [],
      lastFetchedVacancyUnixTime: new Date().getTime() / 1000,
      showFilterPanel: false,
      filterOptions: {
        status: {
          vacancy: false,
          qticket: false,
          opening: false,
        },
      },
    };
  },
  computed: {
    ...(mapGetters({
      categoryList: "getCategoryList",
      floorList: "getFloors",
      areaList: "getAreaList",
    }) as {
      categoryList: () => CategoryOption[];
      floorList: () => string[];
      areaList: () => Area[];
    }),
    placeIdHashList(): string[] {
      return this.placeList.map((place: Place) => place.placeIdHash);
    },
    sortedFloorList(): string[] {
      // drop 'no floor'
      const floorList = [...this.floorList.filter((floor) => !!floor)];

      return floorList.sort((a, b) => {
        const first = a.replace("B", "-").replace("F", "");
        const second = b.replace("B", "-").replace("F", "");
        return parseInt(first) - parseInt(second);
      });
    },
    sortedAreaList(): Area[] {
      return this.areaList.slice().sort((a, b) => a.areaId - b.areaId);
    },
    isFilterApplying(): boolean {
      if (Object.values(this.filterOptions.status).some((useFilter) => useFilter)) {
        return true;
      }

      return (
        !!this.filterOptions.genreId ||
        (this.filterOptions.categoryIdList?.length ?? 0) > 0 ||
        (this.filterOptions.floorList?.length ?? 0) > 0 ||
        (this.filterOptions.areaIdList?.length ?? 0) > 0
      );
    },
  },
  async created() {
    await this.setup();
    this.loop();
    window.addEventListener("scroll", this.lazyLoad);
  },
  beforeDestroy() {
    this.teardown();
  },
  methods: {
    async setup(): Promise<void> {
      try {
        if (this.placeIdHashList.length <= 0) {
          this.lastFetchedVacancyUnixTime = new Date().getTime();
          return;
        }
        this.vacancyList = await fetchVacancyListByPlaceIdHashList(this.placeIdHashList);
        this.lastFetchedVacancyUnixTime = new Date().getTime();
        this.$store.commit("setAlertView", {
          status: false,
        });

        this.hotSpringSettingList = (await fetchResponseHotSpringValuesByPlaceIdHashList(this.placeIdHashList)) ?? [];
      } catch {
        const NETWORK_ERROR_THRESHOLD = 3 * 60 * 1000;
        const currentUnixTime = new Date().getTime();

        if (currentUnixTime >= this.lastFetchedVacancyUnixTime + NETWORK_ERROR_THRESHOLD) {
          this.$store.commit("setAlertView", {
            status: true,
          });
        }
      }
    },
    loop(): void {
      this.timer.fetchingVacancies = window.setTimeout(async () => {
        await this.setup();
        this.loop();
      }, 5000);
    },
    teardown(): void {
      if (this.timer.fetchingVacancies !== undefined) {
        clearTimeout(this.timer.fetchingVacancies);
      }
      window.removeEventListener("scroll", this.lazyLoad);
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    openFilterPanel() {
      this.showFilterPanel = true;
      document.documentElement.style.overflow = "hidden";
    },
    closeFilterPanel() {
      this.showFilterPanel = false;
      setTimeout(() => {
        // show scroll bar again after transition end
        document.documentElement.style.overflow = "auto";
      }, 400);
    },
    async applyFilter(options: FilterOptions) {
      this.filterOptions = options;

      await this.$store.dispatch("fetchFilteredPlaces", {
        websiteId: this.$route.params.websiteId,
        offset: 0,
        limit: 30,
        categoryIdList:
          this.filterOptions.categoryIdList?.length ?? 0 > 0
            ? this.filterOptions.categoryIdList
            : [this.filterOptions.genreId],
        floorList: this.filterOptions.floorList,
        areaIdList: this.filterOptions.areaIdList,
        status: this.filterOptions.status,
      });

      this.scrollTop();
      this.closeFilterPanel();
    },
    async lazyLoad() {
      const rootElement: HTMLElement | null = document.querySelector(".component-index-multiple");
      if (!rootElement) {
        return;
      }
      // 65 for the height of the footer
      const bottomOfWindow = window.scrollY + window.innerHeight >= rootElement.offsetHeight - 65;
      if (bottomOfWindow) {
        await this.$store.dispatch("fetchMorePlaces", {
          websiteId: this.$route.params.websiteId,
          offset: this.placeList.length,
          limit: 10,
          categoryIdList:
            this.filterOptions.categoryIdList?.length ?? 0 > 0
              ? this.filterOptions.categoryIdList
              : [this.filterOptions.genreId],
          floorList: this.filterOptions.floorList,
          areaIdList: this.filterOptions.areaIdList,
          status: this.filterOptions.status,
        });
      }
    },
  },
});
