






























































import MixinPlace from "@/components/place/mixin.vue";

import { Place as BackendPlace, Http } from "@vacancorp/enterprise-vacan.adapter.api.vacanservice.com";

import ImageWithFallback from "@/components/image-with-fallback.vue";
import LabelSet from "@/components/place/label-set.vue";
import VacancyText from "@/components/place/vacancy-text.vue";
import VIcon from "@/components/v/icon.vue";
import VButton from "@/components/v/button.vue";
import CountText from "@/components/place/count-text.vue";

type Equipment = BackendPlace.Equipment;

export default MixinPlace.extend({
  name: "place-card-white-3-images",
  components: {
    ImageWithFallback,
    LabelSet,
    VacancyText,
    VIcon,
    VButton,
    CountText,
  },
  props: {
    equipmentList: { type: Array as () => Equipment[], required: true, default: (): Equipment[] => [] },
    hotSpring: {
      type: Object as () => Http.ResponseHotSpringValues,
      required: false,
    },
  },

  computed: {
    openingHourStringList(): Http.HotSpringSettingValue[] | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.openingHourStringList;
    },
    currentGender(): string | undefined {
      return this.hotSpring === undefined ? undefined : this.hotSpring.currentGender;
    },
    shopClosing(): boolean {
      return (
        this.vacancy.status.state === "closed" ||
        this.vacancy.status.state === "lo" ||
        this.vacancy.status.state === "maintenance"
      );
    },
    locale(): string {
      return this.$i18n.locale;
    },
  },
});
